<template>
    <div class="container">
        <b-row :class="{ 'mt-40': !isResponsive || noResults }">
            <b-col
                v-if="showSidebar"
                class="inner-sidebar"
                :class="{ active: innerSidebarStatus }"
                lg="3"
                md="4"
                sm="12"
            >
                <sidebar
                    :class="{ active: sidebarStatus }"
                    :is-search="true"
                />
            </b-col>
            <b-col
                v-if="showSidebarLoader"
                lg="3"
                md="4"
                sm="12"
                class="sidebar-container"
            >
                <sidebar-loader />
            </b-col>
            <product-listing
                :is-search="true"
                :page-changed="pageChanged"
            />
        </b-row>
        <compare-popup
            v-if="comparePopupIsActive && !hasSubCategories"
            :compare-data="toCompareData"
        />
    </div>
</template>

<script>
    import { i18n } from 'vue-lang-router';
    import { mapActions, mapMutations, mapState } from 'vuex';

    import ComparePopup from '../components/controls/compare/ComparePopup';
    import SidebarLoader from '../components/loaders/category/SidebarLoader';
    import ProductListing from './category/ProductListing';
    import Sidebar from './category/Sidebar';
    export default {
        name: 'Search',
        components: {
            Sidebar,
            ProductListing,
            SidebarLoader,
            ComparePopup,
        },
        computed: {
            ...mapState('category', [
                'category',
                'loading',
                'updatedQuery',
                'filtersCount',
                'products',
                'mobileFiltersActive',
            ]),
            ...mapState('compare', ['toCompareData']),
            ...mapState('category', ['category', 'loading', 'categories', 'products']),
            lang() {
                return i18n.locale;
            },
            showSidebar() {
                return this.products.elements.length > 0;
            },
            showSidebarLoader() {
                return (
                    this.products.elements.length == 0 && this.loading && !this.isResponsive
                );
            },
            noResults() {
                return this.products.elements.length == 0;
            },
            comparePopupIsActive() {
                return Object.keys(this.toCompareData).length > 0;
            },
            hasSubCategories() {
                if (this.category != '') {
                    return this.category.has_sub_categories;
                }
                return true;
            },
            isResponsive() {
                return this.$screen.width < 768;
            },
        },
        data() {
            return {
                paramChanged: false,
                pageChanged: false,
                innerSidebarStatus: false,
                sidebarStatus: false,
                loadingMore: false,
            };
        },
        methods: {
            ...mapMutations('category', ['resetSearchKey']),
            ...mapActions('category', [
                'getProducts',
                'updateQuery',
                'getMoreProductsInfiniteScroll',
            ]),
            async loadProducts() {
                await this.getProducts({ query: null, isSearch: true });
            },
        },
        created() {
            this.loadProducts();
        },
        beforeRouteLeave(to, from, next) {
            this.resetSearchKey();
            next();
        },
        watch: {
            '$route.params.slug3'() {
                this.paramChanged = true;
            },
            '$route.params.slug2'() {
                this.paramChanged = true;
            },
            '$route.params.slug1'() {
                this.paramChanged = true;
            },
            paramChanged: {
                handler(value) {
                    if (value && !this.updatedQuery) {
                        this.loadProducts();
                        this.paramChanged = false;
                        this.pageChanged = true;
                    }
                },
            },
            updatedQuery: {
                handler(value) {
                    if (value) {
                        this.loadProducts();
                        this.pageChanged = false;
                        this.paramChanged = false;
                        this.updateQuery(false);
                    } else {
                        this.updateQuery(false);
                    }
                },
            },
            mobileFiltersActive: {
                handler(value) {
                    if (value) {
                        this.innerSidebarStatus = true;
                        setTimeout(() => {
                            this.sidebarStatus = true;
                        }, 1);
                    } else {
                        this.sidebarStatus = false;
                        setTimeout(() => {
                            this.innerSidebarStatus = false;
                            document.body.classList.remove('no-scrolling');
                        }, 301);
                    }
                },
            },
            loading: {
                handler(value) {
                    if (!value) {
                        this.pageChanged = false;
                    }
                },
            },
            lang: {
                handler() {
                    this.loadProducts();
                },
                deep: true,
            },
        },
        metaInfo() {
            let searchKey = this.$route.query.key;
            return {
                title: this.$t('meta.search.title', { key: searchKey }),
                meta: [
                    {
                        vmid: 'description',
                        name: 'description',
                        content: this.$t('meta.search.description', {
                            key: searchKey,
                        }),
                    },
                    {
                        vmid: 'keywords',
                        name: 'keywords',
                        content: this.$t('meta.search.keywords', { key: searchKey }),
                    },
                    {
                        vmid: 'og:title',
                        property: 'og:title',
                        content: this.$t('meta.search.title', { key: searchKey }),
                        template: (chunk) => `${chunk} | Pescario.ro`,
                    },
                    {
                        vmid: 'og:url',
                        property: 'og:url',
                        content: window.location.origin + this.$route.fullPath,
                    },
                    {
                        vmid: 'og:description',
                        property: 'og:description',
                        content: this.$t('meta.search.description', {
                            key: searchKey,
                        }),
                    },
                    {
                        vmid: 'og:image',
                        property: 'og:image',
                        content: 'https://pescario.ro/logo.webp',
                    },
                ],
                link: [
                    {
                        rel: 'canonical',
                        href: window.location.origin + this.$route.fullPath,
                    },
                ],
            };
        },
    };
</script>
